@import url('https://fonts.googleapis.com/css2?family=Fontdiner+Swanky&family=Roboto:wght@400;700&display=swap');

:root {
  --titleFont:'Fontdiner Swanky', cursive;
  --orange:#f15a24;
  --backgroundGray:#efd8c5;
  --transparentWhite:rgba(255,255,255,0.65);
  --shadow:#606060;
  --successGreen:#5cb85c;
  --cancelGray:#AAAAAA;
  --errorRed: #DC3545;
}

body {
  font-family:'Roboto', sans-serif;
  font-size:16px;
  background-color:var(--backgroundGray);
  position:relative;
  padding:0;
  margin:0;
}

main {
  width:90vw;
  margin:0 auto;
}

form {
  padding-top:15px;
  font-size:1.25em;
  display:grid;
  grid-template-columns:175px 1fr;
  gap:12px;
}

form input, form textarea {
  background-color:var(--transparentWhite);
  border:1px solid #000;
  border-radius:5px;
  padding:6px 12px;
  font-family: 'Roboto', sans-serif;
  resize: none;
}

form textarea {
  min-height:50px;
}

form .buttonWrapper {
  grid-column-start: span 2;
  text-align:right;
}

button {
  background-color:var(--orange);
  color:#fff;
  font-family:var(--titleFont);
  font-size:1em;
  border-radius:5px;
  padding:6px 12px;
  border:0;
  cursor: pointer;
}

@media screen and (max-device-width: 767px) {
  form {
    gap: 6px;
  }

  form input, form textarea {
    grid-column-start:span 2;
  }
}

/* Region: MainHeader */

.main-header {
  background-color:#fff;
  font-family: var(--titleFont);
  color: var(--orange);
  padding: 6px 12px;
  border-bottom: 5px solid var(--orange);
  display:flex;
  justify-content: space-between;
  align-items: center;
}

.main-header h1 {
  margin:0;
  padding:0;
  white-space: nowrap;
}

.main-header a {
  margin:0;
  padding:0;
  color:var(--orange);
  text-decoration:none;
}

.main-header__links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:260px;
}

.main-header__links a {
  font-size:1.25em;
  display:flex;
  justify-content:space-between;
  align-items:center;
}

.main-header__links a:hover {
  text-decoration:underline;
}

.main-header__links a svg {
  margin-right:5px;
  fill: var(--orange);
}

@media screen and (max-device-width: 767px) {
  .main-header__links {
    flex-direction: column;
    align-items: flex-end;
  }

  .main-header__links a {
    white-space:nowrap;
  }

  .main-header__links a:first-of-type {
    margin-bottom:10px;
  }
}

/* Region: Joke */

.joke {
  display:grid;
  grid-template-columns: 25vw 1fr;
  gap:20px;
  padding:6px 12px;
  border-bottom: 2px solid var(--orange);
}

.joke svg {
  max-width:100%;
  height: auto;
}

.joke__text-container {
  display:grid;
  grid-template-columns:1fr;
}

.joke textarea {
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 5px;
  padding: 6px 12px;
  resize:none;
  font-family: 'Roboto', sans-serif;
}

.joke__edit-panel {
  display:flex;
  justify-content: flex-end;
  align-items:center;
}

.joke__edit-panel > button {
  display: flex;
  justify-content:center;
  align-items:center;
  margin-left:20px;
}

.joke__edit-panel button svg {
  fill: #fff;
  margin-right:5px;
}

/* Region: Login & Signup */

.login-form a, .sign-up-form a {
  color: var(--orange);
  text-decoration:none;
}

.login-form a:hover, .sign-up-form a:hover {
  text-decoration:underline;
}

/* EndRegion Login & Signup */

/* Region AuthErrorPopup */

.auth-error-popup {
  position:fixed;
  bottom:25vh;
  left: 50vw;
  transform: translateX(-50%);
  min-width:33vw;
  background-color: var(--errorRed);
  padding:6px 12px;
  border-radius:5px;
  color:#fff;
}

@media screen and (max-device-width: 767px) {
  .auth-error-popup {
    width: 90vw;
  }

}

/* endRegion AuthErrorPopup */
